<template>
    <div>
        <router-view v-if="$route.name === 'permissionView'"/>
        <div class="app-container" v-else>
            <el-form :model="form" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属公司">
                            <el-select v-model="form.companyId" clearable>
                                <el-option v-for="item in companyList" :key="item.id" :value="item.id"
                                           :label="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :span="8">
                    <el-form-item label="设备名称">
                      <el-input v-model="form.deviceName" placeholder="请输入设备名称关键字" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="申请人">
                      <el-input v-model="form.applyMan" placeholder="请输入申请人关键字" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="16">
                    <el-form-item label="申请权限">
                      <el-select v-model="form.rootLevel" clearable>
                        <el-option v-for="item in rootLevelList" :key="item.value" :value="item.value"
                                   :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                    <el-col :span="8">
                        <el-button type="primary" @click="getList(1)">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="list" border style="width: 100%;margin-top: 20px;">
                <el-table-column prop="name" align="center" label="设备名称" width="150px"></el-table-column>
                <el-table-column prop="applyName" align="center" label="申请人" width="150px"></el-table-column>
                <el-table-column prop="levelName" align="center" label="申请权限" width="150px"></el-table-column>
                <el-table-column prop="handleStatus" align="center" label="审核状态" width="150px"></el-table-column>
                <el-table-column prop="locationDesc" align="center" label="地址"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-dropdown v-if="scope.row.handle === 0">
                            <el-button type="primary">
                                审核<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <div @click="handleYes(scope.row.id)">
                                    <el-dropdown-item>通过</el-dropdown-item>
                                </div>
                                <div @click="handleNo(scope.row.id)">
                                    <el-dropdown-item>不通过</el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="total"
                           :current-page="form.pageNum"
                           @current-change="currentChange" style="margin: 20px 0;"/>
        </div>
    </div>
</template>
<script>
import {applyAuditListApi, getApplyAuditBaseApi, operationApplyApi} from '@/api'

export default {
        name: 'permission',
        data() {
            return {
                form: {
                    companyId: '',
                    deviceName: '',
                    applyMan: '',
                    rootLevel: '',
                    pageNum: 1
                },
                list: [],
                total: 0,
                companyList: [],
              rootLevelList: [{
                  value: 1,
                  label: '一级权限'
              },{
                  value: 2,
                  label: '二级权限'
              },{
                  value: 3,
                  label: '三级权限'
              }]
            }
        },
        created() {
            this.getBase()
            this.getList()
        },
        methods: {
            reset(){
              this.form.rootLevel = ''
              this.form.companyId = ''
              this.form.applyMan = ''
              this.form.deviceName = ''
              this.getList()
            },
            currentChange(page) {
                this.form.pageNum = page
                this.getList(1)
            },
            getBase() {
                getApplyAuditBaseApi().then(res => {
                    if (res) {
                        this.companyList = res.data.companyList
                    }
                })
            },
            getList(page) {
                if (page) {
                    this.form.pageNum = 1
                }
                const data = JSON.parse(JSON.stringify(this.form))
                applyAuditListApi(data).then(res => {
                    if (res) {
                        this.list = res.data.list
                        this.total = res.data.totalRow
                    }
                })
            },
            handleYes(id) {
                this.$confirm('确认审核通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    operationApplyApi({
                        id,
                        pass: 1
                    }).then(res => {
                        if (res) {
                            this.$message.success('操作成功!')
                            this.getList(1)
                        }
                    })
                })
            },
            handleNo(id) {
                this.$confirm('确认审核不通过?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    operationApplyApi({
                        id,
                        pass: 2
                    }).then(res => {
                        if (res) {
                            this.$message.success('操作成功!')
                            this.getList(1)
                        }
                    })
                })
            }
        }
    }
</script>
